"use strict";

jQuery(function ($) {

    // /*----- カテゴリー名をクリックで応じた内容を表示 */
    // $(document).ready(function () {
    //     $('.category-list__link').on('click', function (e) {
    //         e.preventDefault();
    //         $('.category-list__link').removeClass('js-active');
    //         $(this).addClass('js-active');
    //         $('.tab-panel').hide();
    //         var category = $(this).data('category'); // data-category属性取得
    //         $(".tab-panel[data-category=\"".concat(category, "\"]")).fadeIn();
    //     });

    //     // 初期状態で最初のタブを表示
    //     $('.tab-panel').hide();
    //     $('.tab-panel[data-category="dinner"]').show();
    // });

    //------ 各店TOP FVのスライダー
    var fvSwiper = new Swiper('.fv-swiper', {
        effect: 'fade',
        speed: 1500,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        allowTouchMove: false
    });

    //------ 各店TOP コンセプトエリアのスライダー(左側見切れタイプ)
    var conceptSwiper = new Swiper('.concept-swiper', {
        loop: true,
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
        },
        speed: 2000,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        slidesPerView: "auto",
        breakpoints: {
            320: {
                centeredSlides: true,
                spaceBetween: 20
            },
            768: {
                centeredSlides: false,
                spaceBetween: 0
            }
        }
    });

    //------ 各店TOP おすすめコースのスライダー
    var recommendSwiper = new Swiper('.recommend-swiper', {
        autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false
        },
        speed: 2000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        spaceBetween: 30,
        slidesPerView: 3,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    });

    //------ 各店TOP メニューのスライダー（SP版のみ）
    if (window.matchMedia('(max-width: 768px)').matches) {
        var menuSwiper = new Swiper('.menu-swiper', {
            slidesPerView: "auto",
            loop: true,
            speed: 5000,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
                disableOnInteraction: false
            }
        });
    }

    //----- 各店 メニュー詳細のスライダー（2個連動）
    //サブスライダー（サムネ）
    var menusubSwiper = new Swiper('.menu-sub__swiper', {
        slidesPerView: 2,
        spaceBetween: 12,
        autoplay: {
            delay: 2000
        },
        speed: 2000,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    });

    //メインスライダー
    var menumainSwiper = new Swiper('.menu-main__swiper', {
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        speed: 500,
        thumbs: {
            swiper: menusubSwiper
        }
    });

    //----- ハンバーガーメニュー
    $(function () {
        var $humBtn = $(".hum-btn");
        var $humMenu = $(".header__hum-menu");
        var $overlay = $("<div class='overlay'></div>").appendTo("body");
        $overlay.css({
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 999,
            display: "none"
        });

        // ハンバーガーボタンのクリック処理
        $humBtn.on("click", function () {
            if ($humMenu.hasClass("is-active")) {
                $humMenu.removeClass("is-active").addClass("is-closing");
                $overlay.fadeOut(600);
                $("body").css("overflow", "");
            } else {
                $humMenu.removeClass("is-closing").addClass("is-active");
                $overlay.fadeIn(600);
                $("body").css("overflow", "hidden");
            }
            $humBtn.toggleClass("active");
            return false;
        });
        $humMenu.on("animationend", function (e) {
            if ($(this).hasClass("is-closing")) {
                $(this).removeClass("is-closing");
            }
        });
        $(".hum-menu__link").on("click", function () {
            $humMenu.removeClass("is-active").addClass("is-closing");
            $overlay.fadeOut(600);
            $("body").css("overflow", "");
            $humBtn.removeClass("active");
        });
        $overlay.on("click", function () {
            $humMenu.removeClass("is-active").addClass("is-closing");
            $overlay.fadeOut(600);
            $("body").css("overflow", "");
            $humBtn.removeClass("active");
        });
    });

    //----- メニュー一覧のアコーディオン（初期状態は閉じている）
    $(document).ready(function () {
        $('.accordion__panel').hide();
        $('.accordion__title-big').removeClass('open');
        $('.accordion__title-big').on('click', function () {
            var $panel = $(this).next('.accordion__panel');
            $panel.slideToggle();
            $(this).toggleClass('open');
        });
    });
});